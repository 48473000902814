import React from 'react'
import './impressum.css';

function Impressum() {
    return (
        <>
            <div className="impressum">
                <h2>Judith Mettke</h2>
                <p>Heilsbronnerstrasse 15</p>
                <p>91560 Heilsbronn</p>
                <p>Tel. 09872 5686</p>
                <p>mail: backscheu@gmail.com</p>
            </div>
        </>
    )
}

export default Impressum
