import React from 'react';
import '../../App.css';
import Dashboard from '../sections/Dashboard';
import Cards from '../sections/Cards';

function Home() {
    return (
        <>
            <Dashboard />
            <Cards/>
        </>        
    );
}

export default Home;

