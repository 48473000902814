import React from 'react';
import './Cards.css';

function Cards() {
    return (
        <div className='cards'>
            <h1>Wir sind bald für Sie da ! </h1>
            {/* <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                        src='images/img-9.jpg'
                        text=' this is a nice picture'
                        label='Adventure'
                        path='services'
                        />
                        <CardItem
                        src='images/img-9.jpg'
                        text=' this is a nice picture'
                        label='Adventure'
                        path='services'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                        src='images/img-9.jpg'
                        text=' this is a nice picture'
                        label='Adventure'
                        path='services'
                        />
                        <CardItem
                        src='images/img-9.jpg'
                        text=' this is a nice picture'
                        label='Adventure'
                        path='services'
                        />
                        <CardItem
                        src='images/img-9.jpg'
                        text=' this is a nice picture'
                        label='Adventure'
                        path='services'
                        />
                    </ul>
                </div>
            </div> */}
        </div>
    );
}

export default Cards
