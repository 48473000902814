import React from 'react'
import '../../App.css';
// import { Button } from './Button';
import './Dashboard.css';

function Dashboard() {
    return (
    <div className='dashboard-container'>
        <img className="front-logo" src='images/logo_klein_einfach_nutzbar.png' alt="Front Logo"/>
    </div>
    )
}

export default Dashboard
