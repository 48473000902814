import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import Navbar from './components/sections/Navbar';
import Home from './components/pages/Home';
import Impressum from './components/pages/Impressum';

function App() {
  return (
    <>
      <Router>
        <Navbar/>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/impressum" exact component={Impressum} />
          </Switch>        
      </Router>
    </>
  );
}

export default App;

