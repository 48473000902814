import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    // makes the mobile size - responsiv
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    // if screensize is changed then call showButton function
    window.addEventListener('resize', showButton);


    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        Bäckerei Scheuerlein
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/impressum' className='nav-links' onClick={closeMobileMenu}>
                                Kontakt
                            </Link>
                        </li>                        
                    </ul>
                    {/* short form : if button true then <Button>SIGN UP</Button> */}
                    {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>}
                </div>                    
            </nav>
        </>
    );
}

export default Navbar
